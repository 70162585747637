<template>
    <div class="home">
        <x-header>子平台管理</x-header>
        <x-content v-if="loading" style="height:100%;" :contentStyle="{alignItems:'center',justifyContent:'center',height:'100%'}">
            <bounce-loading></bounce-loading>
        </x-content>
        <x-content v-else  :contentStyle="{display:'block',width:'calc(100% - 32px)',padding:'16px'}">
            <div class="search_warp">
                <el-row class="list-control_warp">
                      <el-col :span="4">
                        <el-input alwaysShowClear clearable v-model="searchText" @keydown.enter.native="search" placeholder="输入平台名称/域名搜索">
                            <template slot="suffix">
                                <i @click="search" style="cursor: pointer;" class="el-input__icon base-icon-search"></i>
                            </template>
                        </el-input>
                    </el-col>
                    <div style="display: flex; justify-content: flex-end;">
                          <el-button size="medium" type="primary" @click="openDialog" icon="base-icon-plus" >
                                    <span> 新增子平台</span>
                            </el-button>
                    </div>
                  
                </el-row>
            </div>
            <x-card>
                <el-table
                    :data="datas"
                    stripe
                    style="width: 100%">
                    <el-table-column
						prop="title"
                        label="平台名称">
                    </el-table-column>
                    <el-table-column
						prop="domainName"
                        label="域名">
                    </el-table-column>
                    <el-table-column
                        label="所属研究所">
						<template slot-scope="scope">
                            {{scope.row.institutionId| getInstName(that)}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="140"
                        label="操作">
                        <template slot-scope="scope">
                           <el-button type="primary" size="mini" @click="editdomainConfig(scope.row)">编辑</el-button>
							<el-button type="info" size="mini" @click="deletedomainConfig(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="page-warp">
                    <el-pagination
                        @current-change="pageChange"
                        background
                        :hide-on-single-page="total<pageSize"
                        layout="total,prev, pager, next"
					    unitText="条数据"
                        :total="total"
                        :page-size="pageSize"
                        :current-page.sync="page"
                    ></el-pagination>
                </div>
            </x-card>
        </x-content>
        <app-dialog ref="domainConfigDialog" :dialogTitle="domainConfigDialogTitle+'子平台'" originTitle staticDialog width="600px">
            <el-form ref="form"  :rules="rules" :model="formItem">
                <el-form-item prop="title" label="平台名称">
                    <el-input placeholder="请输入平台名称" v-model="formItem.title"></el-input>
                </el-form-item>
                <el-form-item prop="institutionId" label="所属研究所">
                    <el-select v-model="formItem.institutionId" style="width:100%;" filterable >
                        <el-option :value="item.id" :label="item.instituteName" v-for="(item,index) in insList" :key="'ins' + index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="domainName" label="域名">
                    <el-input placeholder="请输入域名,结尾不要带/" v-model="formItem.domainName"></el-input>
                </el-form-item>
                  <el-form-item prop="appid" label="统一登陆APPID">
                    <el-input placeholder="请输入APPID" v-model="formItem.appid"></el-input>
                </el-form-item>
                  <el-form-item prop="secret" label="统一登陆密钥">
                    <el-input placeholder="请输入密钥" v-model="formItem.secret"></el-input>
                </el-form-item>
                  <el-form-item prop="redirectUri" label="登录回调地址">
                    <el-input placeholder="请输入登录回调地址" v-model="formItem.redirectUri"></el-input>
                </el-form-item>
                  <el-form-item prop="quitRedirectUri" label="退出回调地址">
                    <el-input placeholder="请输入退出回调地址" v-model="formItem.quitRedirectUri"></el-input>
                </el-form-item>
                <el-form-item prop="remarks" label="备注信息">
                        <el-input type="textarea" placeholder="请输入备注信息" v-model="formItem.remarks"></el-input>
                </el-form-item>
			</el-form>
            <div class="save_btn_warp">
                <el-button type="primary" style="width:50%;" size="medium" @click="saveData">保存</el-button>
            </div>
        </app-dialog>
    </div>
</template>
<script>
    import { domainConfigList,domainConfigSave,domainConfigDetele } from "../../service/domainConfig"
    import {InstituteListData} from "../../service/institute"
    export default {
        name:'DomainConfigManager',
        data(){
            return {
                loading:false,
                searchText:'',
                datas:[],
                page:1,
                pageSize:10,
                total:0,
                that:this,
                formItem:{
                    institutionId:'',
                    domainName:'',
                    appid:'',
                    secret:'',
                    redirectUri:'',
                    quitRedirectUri:'',
                    remarks:''
				},
				rules:{
                    title:[{required:true,message:'请输入平台名称',trigger:'blur'}],
                    institutionId:[{required:true,message:'请选择所属研究所',trigger:'change'}],
                    domainName:[{required:true,message:'请输入域名',trigger:'blur'}],
                    appid:[{required:true,message:'请输入appid',trigger:'blur'}],
                    secret:[{required:true,message:'请输入secret',trigger:'blur'}],
                    redirectUri:[{required:true,message:'请输入redirectUri',trigger:'blur'}],
                    quitRedirectUri:[{required:true,message:'请输入quitRedirectUri',trigger:'blur'}],
                },
                insList:[],
                domainConfigDialogTitle:''
            }
        },
        filters: {
            getInstName(id, vue) {
                const obj = vue.insList.find((item) => { return item.id === id })
                    if(obj){
                    return obj.instituteName
                    }
                    return id
            },
			},
        created(){
            this.getAllData();
            this.getInsListData();
        },
        computed:{
  
        },
        methods:{
			openDialog(){
                this.domainConfigDialogTitle="新增"
                this.formItem={}

                this.$refs.domainConfigDialog.show();
			},
            editdomainConfig(data){
                //this.$refs['form'].resetFields();
                this.domainConfigDialogTitle="编辑"
                this.formItem = data;
                this.$refs.domainConfigDialog.show();
            },
            saveData(){
                this.$refs['form'].validate(valid => {
                    if (valid) {
                        domainConfigSave(this.formItem).then(e => {
                            let data = this.APIParse(e);
                            if(data){
                                this.$message.success(this.domainConfigDialogTitle+'成功')
                                this.$refs['form'].resetFields();
                                this.$refs.domainConfigDialog.hide();
                                this.getAllData();
                            }
                        }).catch(e => {
                            this.$message.error(e.message)
                        })
                    } else {
                        setTimeout(() => {
                        document.getElementsByClassName("is-error")[0].scrollIntoView({
                            behavior: "smooth",
                             block: "center"
                         });
                    }, 0);
                        return false;
                    }
                })
        		},
            getInsListData(){
                InstituteListData().then(e => {
                    let data = this.APIParse(e);
					this.insList = data
                }).catch(e => {
                    this.$message.error('机构列表加载失败')
                })
            },
            getAllData(){
                this.loading = true;
                domainConfigList(
					this.searchText,
                    this.page,
                    this.pageSize,
                    
                ).then(e => {
                    let data = this.APIParse(e);
                    this.datas = data.records || [];
                    this.total = data.total || 0;
                }).catch(e => {
                    this.$message.error(e.message)
                }).finally(() => {
                    this.loading = false
                })
            },
            search(){
                this.page = 1;
                this.getAllData();
            },
            pageChange(){
                this.getAllData();
            },
            deletedomainConfig(item){
                this.$confirm('此操作将永久删除该子平台, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    domainConfigDetele(item.id).then(e => {
                        let data = this.APIParse(e)
                        if(data){
                            this.$message.success('删除成功')
                            this.search();
                        }else{
                            this.$message.error('删除失败，请重试')
                        }
                    }).catch(ex => {
                        this.$message.error('删除失败，请重试')
                    })
                });
            },
        }   
    }
</script>
<style lang="scss" scoped>
    .home{width: 100%;height: 100%;overflow: auto;}
    .content_warp{display: block;}
    .search_warp{width:100%;margin: 16px 0px;}
    .page-warp{width: 100%;text-align: right;margin-top: 8px;}

    .save_btn_warp{width: 100%;margin-top: 32px;display: flex;align-items: center;justify-content: center;}
</style>